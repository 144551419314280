import React from 'react';
import { motion } from 'framer-motion';
import { useLocation } from '@reach/router';
import { Formik, Form, Field, ErrorMessage } from 'formik';

const countryList = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas (the)',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia (Plurinational State of)',
  'Bonaire, Sint Eustatius and Saba',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory (the)',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cayman Islands (the)',
  'Central African Republic (the)',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos (Keeling) Islands (the)',
  'Colombia',
  'Comoros (the)',
  'Congo (the Democratic Republic of the)',
  'Congo (the)',
  'Cook Islands (the)',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Curaçao',
  'Cyprus',
  'Czechia',
  "Côte d'Ivoire",
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic (the)',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Falkland Islands (the) [Malvinas]',
  'Faroe Islands (the)',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories (the)',
  'Gabon',
  'Gambia (the)',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard Island and McDonald Islands',
  'Holy See (the)',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran (Islamic Republic of)',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  "Korea (the Democratic People's Republic of)",
  'Korea (the Republic of)',
  'Kuwait',
  'Kyrgyzstan',
  "Lao People's Democratic Republic (the)",
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands (the)',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia (Federated States of)',
  'Moldova (the Republic of)',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands (the)',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger (the)',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands (the)',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine, State of',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines (the)',
  'Pitcairn',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Republic of North Macedonia',
  'Romania',
  'Russian Federation (the)',
  'Rwanda',
  'Réunion',
  'Saint Barthélemy',
  'Saint Helena, Ascension and Tristan da Cunha',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Martin (French part)',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten (Dutch part)',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia and the South Sandwich Islands',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan (the)',
  'Suriname',
  'Svalbard and Jan Mayen',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Taiwan (Province of China)',
  'Tajikistan',
  'Tanzania, United Republic of',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands (the)',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates (the)',
  'United Kingdom of Great Britain and Northern Ireland (the)',
  'United States Minor Outlying Islands (the)',
  'United States of America (the)',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela (Bolivarian Republic of)',
  'Viet Nam',
  'Virgin Islands (British)',
  'Virgin Islands (U.S.)',
  'Wallis and Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
  'Åland Islands',
];

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export const EnquiryForm = () => {
  const { pathname } = useLocation();

  const inputClasses =
    'appearance-none rounded-none bg-brand border-b w-full border-current py-3 lg:text-lg placeholder-white placeholder-opacity-30 lg:mb-6 2xl:mb-8';

  const selectClasses =
    'form-select rounded-none bg-brand border-b w-full border-current py-3 lg:text-lg placeholder-white placeholder-opacity-30 lg:mb-6 2xl:mb-8';

  const labelClasses = 'tracking-wide font-display mb-2';

  const errorClasses = 'tracking-wide text-sm my-2';

  return (
    <div>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          occupation: '',
          company: '',
          passportCountry: '',
          countryOfResidence: '',
          visaEnquiryType: '',
        }}
        validate={(values) => {
          const errors = {};
          if (!values.firstName) {
            errors.firstName = 'First Name is Required';
          }
          if (!values.lastName) {
            errors.lastName = 'Last Name is Required';
          }

          if (!values.email) {
            errors.email = 'Email address is Required';
          } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting, setStatus }) => {
          fetch(pathname || '/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
              'form-name': `contact-form`,
              form_name: `contact-form`,
              ...values,
            }),
          })
            .then(() => {
              setSubmitting(false);
              setStatus('success');
            })
            .catch((error) => alert(error));
        }}
      >
        {({
          onChange,
          isSubmitting,
          status,
          values,
          /* and other goodies */
        }) => (
          <Form
            className="w-full relative"
            name="contact-form"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            subject="Website Enquiry"
          >
            {/* Hidden Fields for netlify */}
            <input type="hidden" name="form-name" value="contact-form" />
            <p hidden>
              <label>
                <input name="bot-field" onChange={onChange} />
              </label>
            </p>
            <>
              <div
                className={`font-body transition-all delay-300 duration-300 absolute top-2 left-0 right-0 z-10 ${
                  status === 'success'
                    ? 'opacity-100 pointer-events-all'
                    : 'opacity-0 pointer-events-none'
                }`}
              >
                <motion.h2
                  className={`font-headline text-2xl`}
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                >
                  Thank you we will be in touch.
                </motion.h2>
              </div>
              <div
                className={`grid gap-x-12 grid-cols-1 md:grid-cols-2 gap-y-6 font-body text-medium transition-all duration-300 ${
                  status === 'success' && 'opacity-0 pointer-events-none'
                }`}
              >
                <div className="col-span-1">
                  <label className={labelClasses} htmlFor="firstName">
                    My first name
                  </label>
                  <Field
                    type="text"
                    name="firstName"
                    className={inputClasses}
                    placeholder="James"
                  />
                  <ErrorMessage name="firstName" className={errorClasses} component="div" />
                </div>
                <div className="col-span-1">
                  <label className={labelClasses} htmlFor="lastName">
                    My last name
                  </label>
                  <Field type="text" name="lastName" className={inputClasses} placeholder="Smith" />
                  <ErrorMessage name="lastName" className={errorClasses} component="div" />
                </div>
                <div className="col-span-1">
                  <label className={labelClasses} htmlFor="email">
                    My email address
                  </label>
                  <Field
                    type="email"
                    name="email"
                    className={inputClasses}
                    placeholder="james.smith@email.com"
                  />
                  <ErrorMessage name="email" className={errorClasses} component="div" />
                </div>
                <div className="col-span-1">
                  <label className={labelClasses} htmlFor="phone">
                    My phone number
                  </label>
                  <Field
                    type="text"
                    name="phone"
                    className={inputClasses}
                    placeholder="+64 21 234 567"
                  />
                </div>
                <div className="col-span-1">
                  <label className={labelClasses} htmlFor="occupation">
                    My occupation
                  </label>
                  <Field
                    type="text"
                    name="occupation"
                    className={inputClasses}
                    placeholder="Civil Engineer"
                  />
                </div>
                <div className="col-span-1">
                  <label className={labelClasses} htmlFor="company">
                    My company
                  </label>
                  <Field
                    type="text"
                    name="company"
                    className={inputClasses}
                    placeholder="Company Name"
                  />
                </div>
                <div className="col-span-1">
                  <label className={labelClasses} htmlFor="passportCountry">
                    I am a passport holder of
                  </label>
                  <Field
                    as="select"
                    name="passportCountry"
                    className={selectClasses}
                    placeholder="New Zealand"
                  >
                    <option value="null">Select country</option>
                    {countryList.map((item) => (
                      <option className="text-charcoal" value={`${item}`}>
                        {item}
                      </option>
                    ))}
                  </Field>
                </div>
                <div className="col-span-1">
                  <label className={labelClasses} htmlFor="countryOfResidence">
                    I am currently living in
                  </label>
                  <Field
                    as="select"
                    name="countryOfResidence"
                    className={selectClasses}
                    placeholder="New Zealand"
                  >
                    <option value="null">Select country</option>
                    {countryList.map((item) => (
                      <option className="text-charcoal" value={`${item}`}>
                        {item}
                      </option>
                    ))}
                  </Field>
                </div>
                <div className="col-span-1">
                  <label className={labelClasses} htmlFor="visaEnquiryType">
                    I am enquiring about
                  </label>
                  <Field
                    as="select"
                    name="visaEnquiryType"
                    className={selectClasses}
                    placeholder="Visa Type"
                  >
                    <option className="text-charcoal" value="null">
                      Visa Type
                    </option>
                    <option className="text-charcoal" value="Visa Prequal">
                      Visa Prequal
                    </option>
                    <option className="text-charcoal" value="Company Accreditation">
                      Company Accreditation
                    </option>
                    <option className="text-charcoal" value="Visa Application">
                      Visa Application
                    </option>
                  </Field>
                </div>
                <div className="col-span-1 lg:col-span-2 flex justify-end mt-4">
                  <button
                    className="no-underline py-2 border-b border-white font-display text-lg"
                    type="submit"
                  >
                    Submit Enquiry
                  </button>
                </div>
              </div>
            </>
          </Form>
        )}
      </Formik>
    </div>
  );
};
