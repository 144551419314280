import React from 'react'
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';

import { useAppState } from '../../state/appState';

import { useSiteFooter } from '../../GraphQl/useSiteFooter';

import { MultiLink } from '../ui/links';

import { PortableTextBlock } from '../sanity/portableTextBlock';

import { FormButton } from '../ui/formButton';

export const Footer = () => {
  const { href } = useLocation();

  const { hideFooter } = useAppState();

  const {
    navGeneral,
    navServices,
    addresses,
    callToAction,
    strapline,
    backgroundImage,
  } = useSiteFooter();

  return (
    <footer
      className={`grid grid-cols-footer grid-rows-1 bg-tint leading-relaxed relative ${hideFooter && 'hidden'
        }`}
    >
      <div className="col-start-1 col-end-4 row-start-1 row-end-4 z-0 relative h-full hidden lg:block">
        {backgroundImage && (
          <img
            loading="lazy"
            src={backgroundImage.asset.url}
            className="absolute inset-0 w-full h-full object-cover"
            alt="Visahub Footer"
          />
        )}
      </div>
      <div className="col-start-2 col-end-3 w-full row-start-1 py-24 lg:pt-40 lg:pb-96 2xl:pt-64 2xl:pb-112 3xl:pb-124 z-10 flex flex-col gap-8 items-center justify-center">
        {callToAction && (
          <>
            <PortableTextBlock
              text={callToAction}
              className="max-w-2xl xl:max-w-4xl text-brand font-display text-center text-2xl md:text-3xl lg:text-4xl xl:text-5xl"
            />
            {!String(href).includes('immigration-minister-kris-faafoi-announces-residence-pathway-for-165-000-migrant-workers') && <FormButton />}
          </>
        )}
      </div>
      <div className="col-start-2 col-end-3 row-start-2 z-10 grid gap-x-20 gap-y-8 grid-cols-1 md:grid-cols-2 grid-flow-row lg:text-white ">
        <div className="hidden md:block col-span-1">
          {strapline && (
            <>
              <PortableTextBlock
                text={strapline}
                className="text-brand max-w-lg font-display text-xl md:text-2xl lg:text-3xl xl:text-4xl mb-6"
              />
              <FormButton />
            </>
          )}
        </div>
        <div className="col-span-1 grid grid-cols-2 grid-flow-row gap-8 text-sm lg:text-base">
          <div className="col-span-1">
            {addresses &&
              addresses.map((item) => {
                const { _key, heading, text } = item;
                return (
                  <div key={_key} className="col-span-1">
                    <p className="font-display lg:text-lg">{heading && heading}</p>
                    {text && (
                      <PortableTextBlock text={text} className="lg:text-white prose-sm lg:prose" />
                    )}
                  </div>
                );
              })}
          </div>
          <div className="col-span-1 flex flex-col">
            {navServices &&
              navServices.map((item) => (
                <MultiLink
                  key={item.linkText}
                  className="border-b border-transparent font-display"
                  {...item}
                />
              ))}
            <br />
            {navGeneral &&
              navGeneral.map((item) => (
                <MultiLink key={item.linkText} className="border-b border-transparent" {...item} />
              ))}
          </div>
        </div>
      </div>
      <div className="col-start-2 col-end-3 row-start-3 z-10 w-full border-t border-brand py-12 mt-16 text-gray text-center text-sm flex flex-col md:flex-row justify-between gap-y-6">
        <a
          href="https://www.infrawork.co.nz/"
          target="_blank"
          rel="noreferrer"
          className="font-bold"
        >
          A part of the Infrawork Group
        </a>
        <div className="flex flex-col gap-6 md:flex-row">
          <span>© Visahub 2021. All rights reserved.</span>
          <Link to="/privacy">Privacy</Link>
        </div>
      </div>
    </footer>
  );
}
