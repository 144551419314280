import React, { createContext, useContext } from 'react';

// Hooks
import { useToggle } from '../hooks';

export const AppState = createContext({
  headerLight: false,
  navOpen: false,
  modalOpen: false,
  hideFooter: false,
});

export const AppWrapper = ({ children }) => {
  const [headerLight, toggleHeaderLight] = useToggle(false);      
  const [navOpen, setNavOpen, toggleNav] = useToggle(false);
  const [modalOpen, setModalOpen, toggleModal] = useToggle(false);
  const [hideFooter, setHideFooter, toggleFooter] = useToggle(false);

  return (
    <AppState.Provider
      value={{
        hideFooter,
        setHideFooter,
        toggleFooter,
        navOpen,
        setNavOpen,
        toggleNav,
        modalOpen,
        setModalOpen,
        toggleModal,
        headerLight,
        toggleHeaderLight,
      }}
    >
      {children}
    </AppState.Provider>
  );
};

export const useAppState = () => useContext(AppState);
