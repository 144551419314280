import React from "react";
import AnchorScroll from "react-anchor-link-smooth-scroll";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Link } from "gatsby";

// Hooks & Querys
import { useSanityPath } from "../../hooks";

export const PageLink = ({
  activeClass,
  className,
  page,
  linkText,
  ...rest
}) => {
  const path = useSanityPath(page);
  return (
    <Link
      activeClassName={activeClass}
      className={className}
      to={path}
      {...rest}
    >
      {linkText}
    </Link>
  );
};

export const PathLink = ({
  activeClass,
  className,
  path,
  linkText,
  ...rest
}) => {
  return (
    <Link
      activeClassName={activeClass}
      className={className}
      to={`/${path}`}
      {...rest}
    >
      {linkText}
    </Link>
  );
};

export const PageAnchorLink = ({
  activeClass,
  className,
  anchor,
  page,
  linkText,
  ...rest
}) => {
  const path = useSanityPath(page);
  return (
    <AnchorLink className={className} to={`/services#accreditation`} {...rest}>
      {linkText}
    </AnchorLink>
  );
};

export const AnchorScrollLink = ({ className, anchor, linkText, ...rest }) => {
  return (
    <AnchorScroll className={className} to={`#${anchor}`} {...rest}>
      {linkText}
    </AnchorScroll>
  );
};

export const ExternalLink = ({ className, linkText, link, ...rest }) => {
  return (
    <a className={className} href={link} target="_blank" rel="noreferrer">
      {linkText}
    </a>
  );
};

export const ExternalButton = ({ className, linkText, link, ...rest }) => {
  return (
    <a className={className} href={link} target="_blank" rel="noreferrer">
      {linkText}
    </a>
  );
};

export const PartnerLinks = ({
  className,
  linkText1,
  link1,
  linkText2,
  link2,
  ...rest
}) => {
  return (
    <>
      <a
        href={link1}
        target="_blank"
        rel="noreferrer"
        className="btn-branded mr-8 lg:mr-12"
      >
        {linkText1}
      </a>
      <a href={link2} target="_blank" rel="noreferrer" className="btn-branded">
        {linkText2}
      </a>
    </>
  );
};
export const MultiLink = ({ _type, ...rest }) => {
  const LinkComponent = () => {
    switch (_type) {
      case "pageLink":
        return <PageLink {...rest} />;
      case "pathLink":
        return <PathLink {...rest} />;
      case "externalLink":
        return <ExternalLink {...rest} />;

      default:
        return null;
    }
  };
  return <LinkComponent />;
};
