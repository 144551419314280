import React from "react";
import PortableText from "react-portable-text";
import {
  AnchorScrollLink,
  ExternalButton,
  PageAnchorLink,
  PathLink,
  PartnerLinks,
} from "../ui/links";
import { FormButton } from "../ui/formButton";
import { OneOffFormButton } from "../ui/oneOffFormButton";
import { ImageModule, VideoModule } from "../modules";

const serializers = {
  h1: (props) => <h1 {...props} />,
  h2: (props) => <h2 {...props} />,
  h3: (props) => <h3 {...props} />,
  h4: (props) => <h4 {...props} />,
  h5: (props) => <h5 {...props} />,
  h6: (props) => <h6 {...props} />,
  "super-script": (props) => <sup>{props.children}</sup>,
  "sub-script": (props) => <sub>{props.children}</sub>,
  pathLink: (props) => <PathLink {...props} className="btn-branded" />,
  anchorLink: (props) => (
    <AnchorScrollLink {...props} className="btn-branded" />
  ),
  externalLink: (props) => (
    <ExternalButton {...props} className="btn-rounded" />
  ),
  pageAnchorLink: (props) => (
    <PageAnchorLink {...props} className="btn-branded" />
  ),
  partnerLinks: (props) => <PartnerLinks {...props} />,
  formButton: (props) => <FormButton {...props} />,
  oneOffFormButton: (props) => <OneOffFormButton {...props} />,
  normal: (props) => <p {...props} />,
  strong: (props) => <strong {...props} />,
  imageModule: (props) => <ImageModule {...props} />,
  videoModule: (props) => <VideoModule {...props} />,
}; // eslint-disable-line

export const PortableTextBlock = ({ text, ...props }) => {
  return <PortableText content={text} serializers={serializers} {...props} />;
};
