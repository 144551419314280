import React from 'react';

import { Link } from 'gatsby'

export const OneOffFormButton = () => {

    return (
        <>
            <Link
                to={`/select-enquiry`}
                className="cursor-pointer px-8 py-4 bg-brand text-white-important font-display rounded-full"
            >
                Register for one-off resident visa
            </Link>
        </>
    );
};
