/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from 'react';
import { AppWrapper } from './src/state/appState';
import { CustomerChat } from './src/components/customerChat';


export const wrapRootElement = ({ element }) => {
  return (
    <>
      <AppWrapper>{element}</AppWrapper>
    </>
  );
};


export const wrapPageElement = ({ element, props }) => {
  return (
    <>
      {/* <CustomerChat /> */}
      {element}
    </>
  );
};


const transitionDelay = 300;

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
  } else if (location.action !== 'POP') {
    const savedPosition = getSavedScrollPosition(location);
    window.setTimeout(() => window.scrollTo(...(savedPosition || [0, 0])), transitionDelay);
  }
  return false;
};
