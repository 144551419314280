import React from "react"

import { PageAnchorLink, PageLink, PathLink, ExternalLink } from '../ui';

export const NavLink = ({ _type, ...rest }) => {
  const LinkComponent = () => {
    switch (_type) {
      case 'pageLink':
        return <PageLink activeClass="border-brand font-display" {...rest} />;
      case 'pathLink':
        return <PathLink activeClass="border-brand font-display" {...rest} />;
      case 'pageAnchorLink':
        return <PageAnchorLink activeClass="border-brand font-display" {...rest} />;
      case 'externalLink':
        return <ExternalLink {...rest} />;

      default:
        return null;
    }
  }
  return <LinkComponent />
}
