import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Headroom from 'react-headroom';
import { Link } from 'gatsby';
import { Logo } from '../ui';
import { useSiteConfig } from '../../GraphQl/useSiteConfig';
import { NavLink } from './navLink';
import { MenuToggle } from './menuToggle';
import { MobileNav } from './MobileNav';

// Hooks, Querys & State
import { useAppState } from "../../state/appState"

// Animation Variants
const headerVars = {
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.5,
      delay: 0.4,
    },
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
      delay: 0.5,
    },
  },
};

export const Header = () => {
  
  const [headerPinned, setHeaderPinned] = useState(false);
  
  const { mainNav, loginLink, announcementBar, announcementLink } = useSiteConfig();

  const { headerLight, navOpen, toggleNav, hideFooter } = useAppState();

  return (
    <>
      <AnimatePresence>
        <Headroom
          style={{ zIndex: '2500' }}
          onPin={() => setHeaderPinned(true)}
          onUnpin={() => setHeaderPinned(false)}
          onUnfix={() => setHeaderPinned(false)}
        >
          <motion.header
            variants={headerVars}
            initial="hidden"
            animate="visible"
            exit="hidden"
            id="header"
            className={`font-body tracking-global transition-all duration-200 w-full ${
              headerLight && !headerPinned ? 'text-white' : 'text-charcoal'
            } ${headerPinned && !navOpen ? 'bg-white' : 'bg-transparent'}`}
          >
            {announcementBar && (
              <div
                className={`transform transition-all duration-200 antialiased bg-brand text-white px-4 py-3 text-xs md:text-sm font-display font-medium tracking-wide text-center ${
                  headerPinned && ''
                }`}
              >
                {announcementLink ? (
                  <>
                    <Link to={`/news/${announcementLink.slug.current}`} target="_blank" rel="noreferrer">
                      {announcementBar}
                    </Link>
                  </>
                ) : (
                  <>{announcementBar}</>
                )}
              </div>
            )}
            <div
              className={`flex justify-between items-center py-4 md:py-4 px-4 lg:px-8 xl:px-20 ${
                headerPinned ? 'lg:py-4' : 'lg:py-8'
              }`}
            >
              <Link to="/" className={`w-44 lg:w-52  text-current ${navOpen && 'text-white'}`}>
                <Logo
                  hideFooter={hideFooter}
                  headerLight={headerLight}
                  navOpen={navOpen}
                  headerPinned={headerPinned}
                  className={`transition duration-100`}
                />
              </Link>
              <MenuToggle
                navOpen={navOpen}
                className={`lg:hidden ${navOpen && 'text-white'}`}
                onClick={toggleNav}
              />
              <div className="hidden lg:flex flex-col items-end">
                <div className="flex items-center gap-x-8 mb-4">
                  {loginLink && <a href={loginLink}>Login</a>}
                  {/* <div id="langToggleHeader"></div> */}
                </div>
                <nav id="header-nav" className="flex justify-center items-center gap-x-12">
                  {mainNav.map((item, index) => (
                    <NavLink key={index} className="border-b-2 border-transparent" {...item} />
                  ))}
                </nav>
              </div>
            </div>
          </motion.header>
        </Headroom>
      </AnimatePresence>
      <MobileNav {...mainNav} />
    </>
  );
};
