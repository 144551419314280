import React from 'react';
import { useLocation } from '@reach/router';

import { Link } from 'gatsby';

export const FormButton = () => {
  const { href } = useLocation();

  return (
    <>
      <Link
        to={`/select-enquiry`}
        className='cursor-pointer px-8 py-4 bg-brand text-white font-display rounded-full'
      >
        Register your interest
      </Link>
    </>
  );
};
