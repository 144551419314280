import { graphql, useStaticQuery } from "gatsby"

import { QueryFragments } from "./queryFragments" // eslint-disable-line

export const useSiteFooter = () => {
  const { sanitySiteFooter } = useStaticQuery(graphql`
    query {
      sanitySiteFooter {
        callToAction: _rawCallToAction(resolveReferences: { maxDepth: 10 })
        strapline: _rawStrapline(resolveReferences: { maxDepth: 10 })
        backgroundImage {
          asset {
            url
          }
        }
        addresses {
          _key
          heading: locationHeading
          text: _rawLocationInfo
        }
        navServices {
          ... on SanityPageLink {
            _type
            ...PageLinkFields
          }
          ... on SanityPathLink {
            _type
            ...PathLinkFields
          }
          ... on SanityExternalLink {
            _type
            ...ExternalLinkFields
          }
        }
        navGeneral {
          ... on SanityPageLink {
            _type
            ...PageLinkFields
          }
          ... on SanityPathLink {
            _type
            ...PathLinkFields
          }
          ... on SanityExternalLink {
            _type
            ...ExternalLinkFields
          }
        }
      }
    }
  `);
  return sanitySiteFooter || {}
}
